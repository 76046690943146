import React, { useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "assets/logo0.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Menu } from "primereact/menu";
import "./SignedHeader.css";
import * as actions from "store/actions";
import { Button } from "primereact/button";

import { FiFacebook, FiTwitter, FiYoutube, FiInstagram, FiLinkedin, FiPower } from "react-icons/fi";
import { links, socialMediaItems } from "utils/constants";
import useInternalLinks from "hooks/useInternalLinks";
import { ConfirmPopup } from "primereact/confirmpopup";

const SignedHeader = (props) => {
	const { user } = props.authorization;
	const { profile, profile_loading, profile_error } = props.myProfile;

	const history = useHistory();
	const menu = useRef(false);
	const hamburger = useRef(false);

	const { logout } = props;
	const { fb, tw, yt, ins, lin } = links;
	const { accountLinks, allInternalLinks, upgradeLinks, upgradeUnlimited, upgradeLimited } =
		useInternalLinks();

	const kyc_status = () => {
		let kyc_status = profile ? profile.middleware.kyc_status : user ? user.kyc_status : null;

		if (user.country === "UK") {
			switch (kyc_status) {
				case "lite":
					return accountLinks.concat(upgradeLinks);
				case "limited":
					return accountLinks.concat(upgradeUnlimited);
				case "unlimited":
					return accountLinks;
				default:
					return accountLinks;
			}
		} else {
			switch (kyc_status) {
				case "lite":
					return accountLinks.concat(upgradeLimited);
				default:
					return accountLinks;
			}
		}
	};

	const kyc_level = () => {
		let kyc_status = profile ? profile.middleware.kyc_status : user ? user.kyc_status : null;
		// <li><NavLink to="/upgrade-limited">Updgrade to Limited KYC</NavLink></li>
		if (user.country === "UK") {
			switch (kyc_status) {
				case "lite":
					// return accountLinks.concat(upgradeLinks);
					return (
						<>
							<li><NavLink to="/upgrade-limited">Updgrade to Limited KYC</NavLink></li>
							<li><NavLink to="/upgrade-unlimited">Upgrade to Unlimited KYC</NavLink></li>
						</>
					);
				case "limited":
					// return accountLinks.concat(upgradeUnlimited);
					return (
						<>
							<li><NavLink to="/upgrade-unlimited">Upgrade to Unlimited KYC</NavLink></li>
						</>
					);
				case "unlimited":
					return (
						<></>
					);
				default:
					return <></>;
			}
		} else {
			switch (kyc_status) {
				case "lite":
					// return accountLinks.concat(upgradeLimited);
					return (
						<>
							<li><NavLink to="/upgrade-limited">Upgrade to Limited KYC</NavLink></li>
						</>
					);
				default:
					return <></>;
			}
		}
	}

	const combinedAccountLinks = kyc_status();

	const combinedLinks = allInternalLinks.concat(socialMediaItems);

	const [visible, setVisible] = useState(false);
	const [visible2, setVisible2] = useState(false);

	const handleLogout = () => {
		logout();
		history.push("/");
	};

	React.useEffect(() => {

		// for (const a of document.querySelectorAll(".p-menuitem-text")) {
		// 	console.log("TESttttt")
		// 	if (a.textContent.includes("Paytumi")) {
		// 		console.log("FOUND!!!!!!!!!!!!!!!!!!", a.textContent);
		// 		var uid = a.textContent.replace('Paytumi #', '');
		// 		var menuText = a.textContent.replace(a.textContent, 'Paytumi');
		// 		console.log("USER ID",uid);

		// 		console.log("NEW MENU",menuText);

		// 	}
		// }
		
	},[])

	return (
		<div
			className="bg-white position-sticky top-0"
			style={{
				zIndex: 100,
				boxShadow: "0px 3px 1px rgba(59, 54, 111, 0.25)",
			}}
		>
			<div className="bgYellow" style={{ position: "sticky", top: 0, zIndex: 100 }}>
				<div className="container d-none d-md-block">
					<div className="row py-2">
						<div className="col-11 col-xl-8 col-lg-7 col-md-9 col-sm-11 ">
							<a
								href="mailto:support@accessforex.com"
								target="_blank"
								className="text-disabled me-3 fw-bold"
								style={{ color: "inherit", textDecoration: "none" }}
								rel="noreferrer"
							>
								support@accessforex.com
							</a>
							<a
								href="https://accessforex.com/help-and-support/"
								target="_blank"
								className="me-3 me-sm-2 textBlue fw-bold"
								style={{ textDecoration: "none" }}
								rel="noreferrer"
							>
								Help & Support
							</a>
							<a
								href="https://accessforex.com/blog-and-news/"
								target="_blank"
								className="me-3 me-sm-2 textBlue fw-bold"
								style={{ textDecoration: "none" }}
								rel="noreferrer"
							>
								Blog & News
							</a>
						</div>

						<div className="col-1 col-xl-4 col-lg-5 col-md-3 col-sm-1 text-end">
							<div className="p-0 ">
								<a href={fb} className="ms-3 textBlue" style={{ textDecoration: "none" }}>
									<FiFacebook />
								</a>
								<a href={tw} className="ms-3 textBlue" style={{ textDecoration: "none" }}>
									<FiTwitter />
								</a>
								<a href={yt} className="ms-3 textBlue" style={{ textDecoration: "none" }}>
									<FiYoutube />
								</a>
								<a href={ins} className="ms-3 textBlue" style={{ textDecoration: "none" }}>
									<FiInstagram />
								</a>
								<a href={lin} className="ms-3 textBlue" style={{ textDecoration: "none" }}>
									<FiLinkedin />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row header">
					<div className="col-5 col-lg-3 col-md-2 col-sm-4 py-2 ">
						<NavLink to="/register">
							<img src={logo} alt="ACCESS FOREX" className="px-0 logoDimensions d-block" />
						</NavLink>
					</div>

					<div className="col-7 col-lg-7 col-md-10 col-sm-8 py-4">
						<div className="mt-3 text-end text-lg-center">
							<NavLink
								to="/send-money"
								className="me-1 me-xl-2 me-md-1 textBlue px-1 py-1 d-none d-md-inline"
								style={{ textDecoration: "none" }}
								activeClassName="activeLink"
							>
								Send Money
							</NavLink>

							<NavLink
								to="/transactions"
								className="me-1 me-xl-2 me-md-1 textBlue px-1 py-1 d-none d-md-inline"
								style={{ textDecoration: "none" }}
								activeClassName="activeLink"
							>
								Transactions
							</NavLink>
                  
							{user.country !== "UK" && (
								<NavLink
									to="/upgrade-account"
									className="me-1 me-xl-2 me-md-1 textBlue px-1 py-1 d-none d-lg-inline"
									style={{ textDecoration: "none" }}
									activeClassName="activeLink"
								>
									My Limits
								</NavLink>
								)}
							<NavLink
								to="/beneficiaries"
								className="me-1 me-xl-2 me-md-1 textBlue px-1 py-1 d-none d-md-inline"
								style={{ textDecoration: "none" }}
								activeClassName="activeLink"
							>
								Beneficiaries
							</NavLink>

							<Menu model={combinedAccountLinks} popup ref={menu} />
							<button
								type="button"
								className=" btn btn-light rounded border-0 me-2 m3-sm-0 bg-white textBlue p-0 d-none d-md-inline"
								onClick={(event) => menu.current.toggle(event)}
								style={{ fontSize: "15px", transform: "translateY(-2px)" }}
							>
								My Account <i className="pi pi-angle-down"></i>
							</button>

							<form
								className="me-1 me-xl-2 me-md-1 textBlue mt-n4 px-1 py-1 d-block d-sm-inline"
								action={process.env.REACT_APP_PAYTUMI_URL}
								method="post"
								id="form-full"
								target="_blank"
							>
								<input type="hidden" name="member_id" value={user && user.remitter_id} />
								<input className="bg-white textBlue" type="submit" value="Paytumi" />
							</form>

							<Menu model={combinedLinks} popup ref={hamburger} />
							{/* <Button
								icon="pi pi-bars"
								className="text-center p-button-success py-1 me-2 p-button-outlined d-inline-block d-md-none"
								onClick={(event) => hamburger.current.toggle(event)}
							/> */}
							<input className="menu-btn" type="checkbox" id="menu-btn" />
							<label className="menu-icon" for="menu-btn"><span className="navicon"></span></label>
							<ul className="menu">
								<li style={{marginRight: '10px'}}>
									<form
										className="me-1 me-xl-2 me-md-1 textBlue mt-n4 px-1 py-1 d-block d-sm-inline"
										action={process.env.REACT_APP_PAYTUMI_URL}
										method="post"
										target="_blank"
										id="form-mobile"
									>
										<input type="hidden" name="member_id" value={user && user.remitter_id} />
										<input className="bg-white textBlue" type="submit" value="Paytumi" />
									</form>
								</li>
								<li><NavLink to="/send-money">Send Money</NavLink></li>
								<li><NavLink to="/transactions">Transactions</NavLink></li>
								<li><NavLink to="/upgrade-account">My Limits</NavLink></li>
								<li><NavLink to="/beneficiaries">Beneficiary</NavLink></li>
								<li><NavLink to="/profile">My Profile</NavLink></li>
								<li><NavLink to="/change-password">Change Password</NavLink></li>
								{kyc_level()}
								{/* <li><NavLink to="/upgrade-limited">Updgrade to Limited KYC</NavLink></li> */}
								<li style={{marginRight: '18px'}}>
									<button
										type="button"
										className="btn-mobile btn py-0 bgYellow py-1 px-2 d-inline-block d-lg-none"
										id="triggerLogout"
										onClick={() => setVisible(true)}
									>
										Logout <FiPower />
									</button>
								</li>
							</ul>
							<button
								type="button"
								className="btn-full btn py-0 bgYellow py-1 px-2 d-inline-block d-lg-none"
								id="triggerLogout"
								onClick={() => setVisible(true)}
							>
								Logout <FiPower />
							</button>

							<ConfirmPopup
								target={document.getElementById("triggerLogout")}
								visible={visible}
								onHide={() => setVisible(false)}
								message="Are you sure you want to logout?"
								accept={handleLogout}
							/>
						</div>
					</div>

					<div className="col-12 col-lg-2 col-md-2 col-sm-1 py-4 text-end d-none d-lg-block">
						<button
							type="button"
							className="btn py-0 bgYellow py-1 px-2 mt-2 d-none d-md-inline-block"
							id="triggerLogout2"
							onClick={() => setVisible2(true)}
						>
							Logout <FiPower />
						</button>
						<ConfirmPopup
							target={document.getElementById("triggerLogout2")}
							visible={visible2}
							onHide={() => setVisible2(false)}
							message="Are you sure you want to logout?"
							accept={handleLogout}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

SignedHeader.propTypes = {
	logout: PropTypes.func.isRequired,
	myProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
	myProfile: state.myProfile,
});

export default connect(mapStateToProps, actions)(SignedHeader);
