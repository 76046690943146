import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Register,
  Login,
  SendMoney,
  // UpgradeBetaKYC,
  UpgradeKyc,
  TransactionLogs,
  Beneficiaries,
  TransactionDetails,
  ShareWithFriend,
  ToLimited,
  AddBeneficiary,
  ResetPasswordLink,
  PasswordReset,
  CheckOut,
  UpdateProfile,
  Profile,
  ToUnlimited,
} from "pages";
import UpdateBeneficiary from "pages/Beneficiaries/UpdateBeneficiary";
import NewBeneficiary from "pages/Beneficiaries/NewBeneficiary";
import ChangePassword from "pages/Profile/ChangePassword";
import ProtectedRoute from "./ProtectedRoute";
import AccountLocked from "pages/AccountLocked";

const Routing = () => {
  const routes = [
    {
      path: "/register",
      protected: false,
      component: Register,
    },
    {
      path: "/",
      protected: false,
      component: Login,
    },
    {
      path: "/send-money",
      protected: true,
      component: SendMoney,
    },
    {
      path: "/upgrade-account",
      protected: true,
      // component: UpgradeBetaKYC,
      component: UpgradeKyc,
    },
    {
      path: "/profile/update",
      protected: true,
      component: UpdateProfile,
    },
    {
      path: "/change-password",
      protected: true,
      component: ChangePassword,
    },
    {
      path: "/profile",
      protected: true,
      component: Profile,
    },
    {
      path: "/beneficiaries",
      protected: true,
      component: Beneficiaries,
    },
    {
      path: "/beneficiaries/new",
      protected: true,
      component: NewBeneficiary,
    },
    {
      path: "/beneficiaries/:slug",
      protected: true,
      component: UpdateBeneficiary,
    },
    {
      path: "/transactions",
      protected: true,
      component: TransactionLogs,
    },
    {
      path: "/transactions/:slug",
      protected: true,
      component: TransactionDetails,
    },
    {
      path: "/share",
      protected: true,
      component: ShareWithFriend,
    },
    {
      path: "/upgrade-limited",
      protected: true,
      component: ToLimited,
    },
    {
      path: "/upgrade-unlimited",
      protected: true,
      component: ToUnlimited,
    },
    {
      path: "/account-locked",
      protected: false,
      component: AccountLocked,
    },
    {
      path: "/add-beneficiary",
      protected: true,
      component: AddBeneficiary,
    },
    {
      path: "/reset-password-link",
      protected: false,
      component: ResetPasswordLink,
    },
    {
      path: "/password-reset",
      protected: false,
      component: PasswordReset,
    },
    {
      path: "/checkout/:slug",
      protected: true,
      component: CheckOut,
    },
  ];

  return (
    <Switch>
      {routes.map((route, key) => {
        if (route.protected) return <ProtectedRoute exact path={route.path} key={key} component={route.component} />;
        return <Route exact path={route.path} key={key} component={route.component} />;
      })}
    </Switch>
  );
};

export default Routing;
