import Axios from "axios";
import Cookies from "js-cookie";

import Bugsnag from "@bugsnag/js";

import {
  AUTHORIZATION_LOADING,
  AUTHORIZATION,
  AUTHORIZATION_ERROR,
  CLEAR_AUTHORIZATION_ERROR,
  CLEAR_AUTHORIZATION,
  FETCH_UIOPTIONS_LOADING,
  FETCH_UIOPTIONS,
  FETCH_UIOPTIONS_ERROR,
  CLEAR_FETCH_UIOPTIONS,
  CLEAR_FETCH_UIOPTIONS_ERROR,
  REMIT_ONE,
  REMIT_ONE_ERROR,
  CLEAR_REMIT_ONE_ERROR,
  CLEAR_REMIT_ONE,
  REMIT_ONE_LOADING,
  SET_TRANSACTION_CHARGES_LOADING,
  SET_TRANSACTION_CHARGES_ERROR,
  TRANSACTION_CHARGES,
  CLEAR_TRANSACTION_CHARGES_ERROR,
  CLEAR_TRANSACTION_CHARGES,
  SET_NEW_BENEFICIARY_LOADING,
  NEW_BENEFICIARY,
  SET_NEW_BENEFICIARY_ERROR,
  CLEAR_NEW_BENEFICIARY_ERROR,
  CLEAR_NEW_BENEFICIARY,
  SET_CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD,
  SET_CHANGE_PASSWORD_ERROR,
  CLEAR_CHANGE_PASSWORD_ERROR,
  CLEAR_CHANGE_PASSWORD,
  SET_RESET_PASSWORD_LOADING,
  RESET_PASSWORD,
  SET_RESET_PASSWORD_ERROR,
  CLEAR_RESET_PASSWORD_ERROR,
  CLEAR_RESET_PASSWORD,
  SET_UPDATE_BENEFICIARY_LOADING,
  UPDATE_BENEFICIARY,
  SET_UPDATE_BENEFICIARY_ERROR,
  CLEAR_UPDATE_BENEFICIARY_ERROR,
  CLEAR_UPDATE_BENEFICIARY,
  SET_BENEFICIARIES_LOADING,
  ALL_BENEFICIARIES,
  ALL_BENEFICIARIES_ERROR,
  CLEAR_ALL_BENEFICIARIES,
  CLEAR_ALL_BENEFICIARIES_ERROR,
  SET_SINGLE_BENEFICIARY_LOADING,
  SINGLE_BENEFICIARY,
  SINGLE_BENEFICIARY_ERROR,
  CLEAR_SINGLE_BENEFICIARY,
  CLEAR_SINGLE_BENEFICIARY_ERROR,
  SET_NEW_TRANSACTION_LOADING,
  SET_NEW_TRANSACTION_ERROR,
  NEW_TRANSACTION,
  CLEAR_NEW_TRANSACTION_ERROR,
  CLEAR_NEW_TRANSACTION,
  GET_TRANSACTIONS,
  CLEAR_GET_TRANSACTIONS,
  CLEAR_GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_LOADING,
  GET_LIMITS,
  CLEAR_GET_LIMITS,
  CLEAR_GET_LIMITS_ERROR,
  GET_LIMITS_ERROR,
  GET_LIMITS_LOADING,
  GET_TRANSACTION,
  CLEAR_GET_TRANSACTION,
  CLEAR_GET_TRANSACTION_ERROR,
  GET_TRANSACTION_ERROR,
  GET_TRANSACTION_LOADING,
  SET_UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE,
  SET_UPDATE_PROFILE_ERROR,
  CLEAR_UPDATE_PROFILE_ERROR,
  CLEAR_UPDATE_PROFILE,
  CONFIRM_TRANSACTION,
  CONFIRM_TRANSACTION_LOADING,
  CONFIRM_TRANSACTION_ERROR,
  CLEAR_CONFIRM_TRANSACTION_ERROR,
  CLEAR_CONFIRM_TRANSACTION,
  STORE_ONBOARDING_PAYLOAD,
  CLEAR_ONBOARDING_PAYLOAD,
  CLEAR_TRANSACTION_LOADING,
  CLEAR_TRANSACTION_PAYLOAD,
  SET_CLEAR_TRANSACTION_ERROR,
  CLEAR_TRANSACTION_PAYLOAD_ERROR,
  SET_CLEAR_TRANSACTION_STATE,
  UPGRADE_PROFILE,
  UPGRADE_PROFILE_ERROR,
  SET_UPGRADE_PROFILE_LOADING,
  CLEAR_UPGRADE_PROFILE_ERROR,
  CLEAR_UPGRADE_PROFILE,
  CLEAR_INITIATE_PAYMENT,
  CLEAR_INITIATE_PAYMENT_ERROR,
  INITIATE_PAYMENT_LOADING,
  INITIATE_PAYMENT_ERROR,
  INITIATE_PAYMENT,
  SET_POLL_PAYMENT_LOADING,
  CLEAR_POLL_PAYMENT_ERROR,
  CLEAR_POLL_PAYMENT,
  POLL_PAYMENT_ERROR,
  POLL_PAYMENT,
  PROFILE,
  PROFILE_ERROR,
  PROFILE_LOADING,
  CLEAR_PROFILE_ERROR,
  CLEAR_PROFILE,
  CLEAR_SEARCH_REMITTER_ERROR,
  SEARCH_REMITTER_LOADING,
  CLEAR_SEARCH_REMITTER,
  SEARCH_REMITTER_ERROR,
  SEARCH_REMITTER,
  CLEAR_SEND_OTP,
  CLEAR_SEND_OTP_ERROR,
  SEND_OTP_LOADING,
  SEND_OTP_ERROR,
  SEND_OTP,
  CLEAR_VERIFY_OTP,
  CLEAR_VERIFY_OTP_ERROR,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_ERROR,
  VERIFY_OTP,
  CLEAR_ONBOARD_REMITTER,
  CLEAR_ONBOARD_REMITTER_ERROR,
  ONBOARD_REMITTER_LOADING,
  ONBOARD_REMITTER_ERROR,
  ONBOARD_REMITTER,
  CLEAR_CHECK_AWAIT_PAYMENT,
  CLEAR_CHECK_AWAIT_PAYMENT_ERROR,
  CHECK_AWAIT_PAYMENT_LOADING,
  CHECK_AWAIT_PAYMENT_ERROR,
  CHECK_AWAIT_PAYMENT,
} from "./types";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "https://afmiddleware.growthsense.co.za/v1";

export const logout = () => {
  return (dispatch) => {
    Cookies.remove("access");
    dispatch(clearBasicRegistration());
    dispatch(clearLogin());
    dispatch(clearUIoptions());
    dispatch(clearRemitOne());
    dispatch(clearTransactionCharges());
    dispatch(clearNewBeneficiary());
    dispatch(clearUpdateBeneficiary());
    dispatch(clearGetTransactions());
    dispatch(clearGetLimits());
    dispatch(clearNewTransaction());
    dispatch(clearUpdateProfile());
    dispatch(clearConfirmTransaction());
    dispatch(clearOnboardingPayload());
    dispatch(setClearTransactionState());
    dispatch(clearUpgradeProfile());
    dispatch(clearInitiatePayment());
    dispatch(clearPollPayment());
    dispatch(clearProfile());
    dispatch(clearSingleBeneficiary());
    dispatch(clearAllBeneficiaries());
    dispatch(clearGetSingleTransaction());
    dispatch(clearSearchRemitter());
    dispatch(clearSendOTP());
    dispatch(clearVerifyOTP());
    dispatch(clearOnboardRemitter());
    dispatch(clearCheckAwaitPayment());
  };
};

export const basicRegistration = (data) => (dispatch) => {
  dispatch(setBasicRegistrationLoading());
  data.subscribe = `${data.subscribe}`;
  Axios.post(`${BASE_URL}/remitter/auth/register/basic`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      const { token } = res.data.result;
      Cookies.set("access", token);
      dispatch({
        type: AUTHORIZATION,
        payload: res.data.result.user,
      });
    })
    .catch((err) => {
      let error = formatErrors(err);
      dispatch({
        type: AUTHORIZATION_ERROR,
        payload: error,
      });
    });
};

export const setBasicRegistrationLoading = () => {
  return {
    type: AUTHORIZATION_LOADING,
  };
};

export const clearBasicRegistrationError = () => {
  return {
    type: CLEAR_AUTHORIZATION_ERROR,
  };
};

export const clearBasicRegistration = () => {
  return {
    type: CLEAR_AUTHORIZATION,
  };
};

export const fullRegistration = (data) => (dispatch) => {
  dispatch(setBasicRegistrationLoading());

  let formData = new FormData();

  formData.append("first_name", data.first_name);
  formData.append("middle_name", data.middle_name);
  formData.append("last_name", data.last_name);
  formData.append("mobile", data.mobile);
  formData.append("dob", data.dob);
  formData.append("gender", data.gender);
  formData.append("id1_details", data.id1_details);
  formData.append("id1_type", data.id1_type);
  formData.append("id2", data.id2);
  formData.append("id2_type", data.id2_type);
  formData.append("purpose_of_remittance", data.purpose_of_remittance);
  formData.append("nationality", data.nationality);
  formData.append("postal_code", data.postal_code);
  formData.append("address_line_1", data.address_line_1);
  formData.append("address_line_2", data.address_line_2);
  formData.append("city", data.city);
  formData.append("state_province", data.state_province);
  formData.append("id1_issue_place", data.id1_issue_place);
  formData.append("id1_issue_country", data.id1_issue_country);
  formData.append("id1_expiry", data.id1_expiry);
  formData.append("id3_details", data.id3_details);
  formData.append("id3_type", data.id3_type);
  formData.append("id4_details", data.id4_details);
  formData.append("id4_type", data.id4_type);
  formData.append("default_transfer_purpose", data.default_transfer_purpose);

  const runFullyRegistration = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/auth/register/full`, formData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: AUTHORIZATION,
          payload: res.data.result.user,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: AUTHORIZATION_ERROR,
          payload: error,
        });
      });
  };
  runFullyRegistration();
};

export const login = (data) => (dispatch) => {
  dispatch(setLoginLoading());
  Axios.post(`${BASE_URL}/remitter/auth/login`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      const { token } = res.data.result;
      Cookies.set("access", token);
      dispatch({
        type: AUTHORIZATION,
        payload: res.data.result.user,
      });
    })
    .catch((err) => {
      let errors = formatErrors(err);
      dispatch({
        type: AUTHORIZATION_ERROR,
        payload: errors,
      });
    });
};

export const checkAwaitPayment = () => (dispatch) => {
  dispatch(checkAwaitPaymentLoading());

  const runCheckAwaitPayment = async () => {
    let token = await Cookies.get("access");

    Axios.get(`${BASE_URL}/remitter/pending-transactions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: CHECK_AWAIT_PAYMENT,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: CHECK_AWAIT_PAYMENT_ERROR,
          payload: error,
        });
      });
  };

  runCheckAwaitPayment();
};

export const checkAwaitPaymentLoading = () => {
  return {
    type: CHECK_AWAIT_PAYMENT_LOADING,
  };
};

export const clearCheckAwaitPaymentError = () => {
  return {
    type: CLEAR_CHECK_AWAIT_PAYMENT_ERROR,
  };
};

export const clearCheckAwaitPayment = () => {
  return {
    type: CLEAR_CHECK_AWAIT_PAYMENT,
  };
};

export const setLoginLoading = () => {
  return {
    type: AUTHORIZATION_LOADING,
  };
};

export const clearLogin = () => {
  return {
    type: CLEAR_AUTHORIZATION,
  };
};

export const clearAuthorizationError = () => {
  return {
    type: CLEAR_AUTHORIZATION_ERROR,
  };
};

// UI Options

export const getUIoptions = () => (dispatch) => {
  dispatch(setFetchUIoptionsLoading());
  Axios.get(`${BASE_URL}/remitter/ui`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-API-KEY": "X_gasurGY2H,%V_G",
    },
  })
    .then((res) => {
      dispatch({
        type: FETCH_UIOPTIONS,
        payload: res.data,
      });
    })
    .catch((err) => {
      let error = formatErrors(err);
      dispatch({
        type: FETCH_UIOPTIONS_ERROR,
        payload: error,
      });
    });
};

export const setFetchUIoptionsLoading = () => {
  return {
    type: FETCH_UIOPTIONS_LOADING,
  };
};

export const clearUIoptions = () => {
  return {
    type: CLEAR_FETCH_UIOPTIONS,
  };
};

export const clearUIoptionsError = () => {
  return {
    type: CLEAR_FETCH_UIOPTIONS_ERROR,
  };
};

// LIMITS

export const getLimits = () => (dispatch) => {
  dispatch(getLimitsLoading());
  const runGetLimits = async () => {
    let token = await Cookies.get("access");
    Axios.get(`${BASE_URL}/remitter/limits`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let payload = res.data.result;

        dispatch({
          type: GET_LIMITS,
          payload: payload,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: GET_LIMITS_ERROR,
          payload: error,
        });
      });
  };

  runGetLimits();
};

export const getLimitsLoading = () => {
  return {
    type: GET_LIMITS_LOADING,
  };
};

export const clearGetLimitsError = () => {
  return {
    type: CLEAR_GET_LIMITS_ERROR,
  };
};

export const clearGetLimits = () => {
  return {
    type: CLEAR_GET_LIMITS,
  };
};

export const remitOne = () => (dispatch) => {
  dispatch(remitOneLoading());
  const runRemitOne = async () => {
    let token = await Cookies.get("access");

    Axios.post(
      `${BASE_URL}/remitter/auth/register/remitone`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        dispatch({
          type: REMIT_ONE,
          payload: res.data.result.user,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: REMIT_ONE_ERROR,
          payload: error,
        });
      });
  };

  runRemitOne();
};

export const remitOneLoading = () => {
  return {
    type: REMIT_ONE_LOADING,
  };
};

export const clearRemitOneError = () => {
  return {
    type: CLEAR_REMIT_ONE_ERROR,
  };
};

export const clearRemitOne = () => {
  return {
    type: CLEAR_REMIT_ONE,
  };
};

export const getTransactionCharges = (data) => (dispatch) => {
  dispatch(setTransactionChargesLoading());
  const runTransactionCharges = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/transactions/charges`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: TRANSACTION_CHARGES,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_TRANSACTION_CHARGES_ERROR,
          payload: error,
        });
      });
  };

  runTransactionCharges();
};

export const setTransactionChargesLoading = () => {
  return {
    type: SET_TRANSACTION_CHARGES_LOADING,
  };
};

export const clearTransactionChargesError = () => {
  return {
    type: CLEAR_TRANSACTION_CHARGES_ERROR,
  };
};

export const clearTransactionCharges = () => {
  return {
    type: CLEAR_TRANSACTION_CHARGES,
  };
};

export const clearTransaction = (data) => (dispatch) => {
  dispatch(clearTransactionLoading());

  const runClearTransactions = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/transactions/clear`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: CLEAR_TRANSACTION_PAYLOAD,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_CLEAR_TRANSACTION_ERROR,
          payload: error,
        });
      });
  };

  runClearTransactions();
};

export const clearTransactionLoading = () => {
  return {
    type: CLEAR_TRANSACTION_LOADING,
  };
};

export const clearTransactionPayloadError = () => {
  return {
    type: CLEAR_TRANSACTION_PAYLOAD_ERROR,
  };
};

export const setClearTransactionState = () => {
  return {
    type: SET_CLEAR_TRANSACTION_STATE,
  };
};

export const newBeneficiary = (data) => (dispatch) => {
  dispatch(setNewBeneficiaryLoading());
  const runNewBeneficiary = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/beneficiaries/new`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: NEW_BENEFICIARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_NEW_BENEFICIARY_ERROR,
          payload: error,
        });
      });
  };

  runNewBeneficiary();
};

export const setNewBeneficiaryLoading = () => {
  return {
    type: SET_NEW_BENEFICIARY_LOADING,
  };
};

export const clearNewBeneficiaryError = () => {
  return {
    type: CLEAR_NEW_BENEFICIARY_ERROR,
  };
};

export const clearNewBeneficiary = () => {
  return {
    type: CLEAR_NEW_BENEFICIARY,
  };
};

// Change Password

export const changePassword = (data) => (dispatch) => {
  dispatch(setChangePasswordLoading());
  const runChangePassword = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/auth/change-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: CHANGE_PASSWORD,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_CHANGE_PASSWORD_ERROR,
          payload: error,
        });
      });
  };

  runChangePassword();
};

export const setChangePasswordLoading = () => {
  return {
    type: SET_CHANGE_PASSWORD_LOADING,
  };
};

export const clearChangePasswordError = () => {
  return {
    type: CLEAR_CHANGE_PASSWORD_ERROR,
  };
};

export const clearChangePassword = () => {
  return {
    type: CLEAR_CHANGE_PASSWORD,
  };
};
// Reset Password

export const resetPassword = (data) => (dispatch) => {
  dispatch(setResetPasswordLoading());
  const runResetPassword = async () => {
    Axios.post(`${BASE_URL}/remitter/auth/forgot-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        dispatch({
          type: RESET_PASSWORD,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_RESET_PASSWORD_ERROR,
          payload: error,
        });
      });
  };

  runResetPassword();
};

export const setResetPasswordLoading = () => {
  return {
    type: SET_RESET_PASSWORD_LOADING,
  };
};

export const clearResetPasswordError = () => {
  return {
    type: CLEAR_RESET_PASSWORD_ERROR,
  };
};

export const clearResetPassword = () => {
  return {
    type: CLEAR_RESET_PASSWORD,
  };
};

// Update Beneficiary

export const updateBeneficiary = (data) => (dispatch) => {
  dispatch(setUpdateBeneficiaryLoading());

  const runUpdateBeneficiary = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/beneficiaries/update`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: UPDATE_BENEFICIARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_UPDATE_BENEFICIARY_ERROR,
          payload: error,
        });
      });
  };

  runUpdateBeneficiary();
};

export const setUpdateBeneficiaryLoading = () => {
  return {
    type: SET_UPDATE_BENEFICIARY_LOADING,
  };
};

export const clearUpdateBeneficiaryError = () => {
  return {
    type: CLEAR_UPDATE_BENEFICIARY_ERROR,
  };
};

export const clearUpdateBeneficiary = () => {
  return {
    type: CLEAR_UPDATE_BENEFICIARY,
  };
};

// Get Beneficiaries
export const getAllBeneficiaries = () => (dispatch) => {
  dispatch(setBeneficiariesLoading());

  const runFetchBeneficiaries = async () => {
    let token = await Cookies.get("access");

    Axios.get(`${BASE_URL}/remitter/beneficiaries`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let payload = res.data.result.beneficiaries.beneficiary;

        dispatch({
          type: ALL_BENEFICIARIES,
          // payload: Array.isArray(payload) ? payload : [payload],
          payload: Array.isArray(payload) ? (payload.length ? payload : null) : payload ? [payload] : null,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: ALL_BENEFICIARIES_ERROR,
          payload: error,
        });
      });
  };

  runFetchBeneficiaries();
};

export const setBeneficiariesLoading = () => {
  return {
    type: SET_BENEFICIARIES_LOADING,
  };
};

export const clearAllBeneficiaries = () => {
  return {
    type: CLEAR_ALL_BENEFICIARIES,
  };
};

export const clearAllBeneficiariesError = () => {
  return {
    type: CLEAR_ALL_BENEFICIARIES_ERROR,
  };
};

// Get Single Beneficiary
export const getSingleBeneficiary = (id) => (dispatch) => {
  dispatch(setSingleBeneficiaryLoading());

  const runFetchSingleBeneficiary = async () => {
    let token = await Cookies.get("access");

    Axios.get(`${BASE_URL}/remitter/beneficiaries/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: SINGLE_BENEFICIARY,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SINGLE_BENEFICIARY_ERROR,
          payload: error,
        });
      });
  };

  runFetchSingleBeneficiary();
};

export const setSingleBeneficiaryLoading = () => {
  return {
    type: SET_SINGLE_BENEFICIARY_LOADING,
  };
};

export const clearSingleBeneficiary = () => {
  return {
    type: CLEAR_SINGLE_BENEFICIARY,
  };
};

export const clearSingleBeneficiaryError = () => {
  return {
    type: CLEAR_SINGLE_BENEFICIARY_ERROR,
  };
};

// New Transaction

export const newTransaction = (data) => (dispatch) => {
  dispatch(setNewTransactionLoading());

  const runNewTransaction = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/transactions/new`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: NEW_TRANSACTION,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_NEW_TRANSACTION_ERROR,
          payload: error,
        });
      });
  };

  runNewTransaction();
};

export const setNewTransactionLoading = () => {
  return {
    type: SET_NEW_TRANSACTION_LOADING,
  };
};

export const clearNewTransactionError = () => {
  return {
    type: CLEAR_NEW_TRANSACTION_ERROR,
  };
};

export const clearNewTransaction = () => {
  return {
    type: CLEAR_NEW_TRANSACTION,
  };
};

// GET TRANSACTIONS

export const getAllTransactions = () => (dispatch) => {
  dispatch(getTransactionsLoading());
  const runGetTransactions = async () => {
    let token = await Cookies.get("access");
    Axios.get(`${BASE_URL}/remitter/transactions`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // let payload = res.data.result.transactions.transaction;
        let payload = res.data.result.transactions;

        dispatch({
          type: GET_TRANSACTIONS,
          // payload: payload
          payload: Array.isArray(payload) ? (payload.length ? payload : null) : payload ? [payload] : null,
          // payload: Array.isArray(payload) ? "true" : "false"
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: GET_TRANSACTIONS_ERROR,
          payload: error,
        });
      });
  };

  runGetTransactions();
};

export const getTransactionsLoading = () => {
  return {
    type: GET_TRANSACTIONS_LOADING,
  };
};

export const clearGetTransactionsError = () => {
  return {
    type: CLEAR_GET_TRANSACTIONS_ERROR,
  };
};

export const clearGetTransactions = () => {
  return {
    type: CLEAR_GET_TRANSACTIONS,
  };
};

// GET SINGLE TRANSACTION

export const getSingleTransaction = (trans_ref) => (dispatch) => {
  dispatch(getSingleTransactionLoading());
  const runGetSingleTransaction = async () => {
    let token = await Cookies.get("access");
    Axios.get(`${BASE_URL}/remitter/transactions/${trans_ref}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION,
          // payload: res.data.result.transaction,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: GET_TRANSACTION_ERROR,
          payload: error,
        });
      });
  };

  runGetSingleTransaction();
};

export const getSingleTransactionLoading = () => {
  return {
    type: GET_TRANSACTION_LOADING,
  };
};

export const clearGetSingleTransactionError = () => {
  return {
    type: CLEAR_GET_TRANSACTION_ERROR,
  };
};

export const clearGetSingleTransaction = () => {
  return {
    type: CLEAR_GET_TRANSACTION,
  };
};

// PROFILE

export const getProfile = (type) => (dispatch) => {
  dispatch(setGetProfileLoading(type));

  const runGetProfiles = async () => {
    let token = await Cookies.get("access");

    Axios.get(`${BASE_URL}/remitter/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: type === "auth" ? AUTHORIZATION : PROFILE,
          payload: type === "auth" ? res.data.result.middleware : res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: type === "auth" ? AUTHORIZATION_ERROR : PROFILE_ERROR,
          payload: error,
        });
      });
  };
  runGetProfiles();
};

export const setGetProfileLoading = (type) => {
  return {
    type: type === "auth" ? AUTHORIZATION_LOADING : PROFILE_LOADING,
  };
};

export const clearProfileError = (type) => {
  return {
    type: type === "auth" ? CLEAR_AUTHORIZATION_ERROR : CLEAR_PROFILE_ERROR,
  };
};

export const clearProfile = () => {
  return {
    type: CLEAR_PROFILE,
  };
};

/********************************************************************************************
 *   UPDATE PROFILE
 *********************************************************************************************/

export const updateProfile = (data) => (dispatch) => {
  dispatch(setUpdateProfileLoading());
  let formData = new FormData();

  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("middle_name", data.middle_name);
  formData.append("mobile", data.mobile);
  formData.append("email", data.email);
  formData.append("nationality", data.nationality);
  formData.append("dob", data.dob);
  formData.append("gender", data.gender);
  formData.append("post_code", data.post_code);
  formData.append("address_line_1", data.address_line_1);
  formData.append("address_line_2", data.address_line_2);
  formData.append("city", data.city);
  formData.append("state_province", data.state_province);
  formData.append("id1_type", data.id1_type);
  formData.append("id1", data.id1);
  formData.append("id1_scan2", data.id1_scan2);
  formData.append("id2_type", data.id2_type);
  formData.append("id2", data.id2);
  formData.append("id3_type", data.id3_type);
  formData.append("id3", data.id3);
  formData.append("id4_type", data.id4_type);
  formData.append("id4", data.id4);
  formData.append("id1_details", data.id1_details);
  formData.append("id1_issue_place", data.id1_issue_place);
  formData.append("id1_issue_country", data.id1_issue_country);
  formData.append("id1_expiry", data.id1_expiry);

  const runUpdateProfile = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/profile/update`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: UPDATE_PROFILE,
          payload: res.data.result.message,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SET_UPDATE_PROFILE_ERROR,
          payload: error,
        });
      });
  };

  runUpdateProfile();
};

export const setUpdateProfileLoading = () => {
  return {
    type: SET_UPDATE_PROFILE_LOADING,
  };
};

export const clearUpdateProfileError = () => {
  return {
    type: CLEAR_UPDATE_PROFILE_ERROR,
  };
};

export const clearUpdateProfile = () => {
  return {
    type: CLEAR_UPDATE_PROFILE,
  };
};

export const confirmTransaction = (data) => (dispatch) => {
  dispatch(setConfirmTransactionLoading());
  const runConfirmTransaction = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/transactions/confirm`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: CONFIRM_TRANSACTION,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: CONFIRM_TRANSACTION_ERROR,
          payload: error,
        });
      });
  };
  runConfirmTransaction();
};

export const setConfirmTransactionLoading = () => {
  return {
    type: CONFIRM_TRANSACTION_LOADING,
  };
};

export const clearConfirmTransactionError = () => {
  return {
    type: CLEAR_CONFIRM_TRANSACTION_ERROR,
  };
};

export const clearConfirmTransaction = () => {
  return {
    type: CLEAR_CONFIRM_TRANSACTION,
  };
};

export const storeOnboardingPayload = (data) => (dispatch) => {
  dispatch({
    type: STORE_ONBOARDING_PAYLOAD,
    payload: data,
  });
};

export const clearOnboardingPayload = () => {
  return {
    type: CLEAR_ONBOARDING_PAYLOAD,
  };
};

export const upgradeProfile = (data) => (dispatch) => {
  dispatch(setUpgradeProfileLoading());
  let formData = new FormData();

  formData.append("upgrade_type", data.upgrade_type);
  formData.append("id1_type", data.id1_type);
  formData.append("nationality", data.nationality);
  formData.append("email", data.email);
  formData.append("id1_details", data.id1_details);
  formData.append("id1_issue_place", data.id1_issue_place);
  formData.append("id1_issue_country", data.id1_issue_country);
  formData.append("id1_expiry", data.id1_expiry);
  formData.append("id1_scan2", data.id1_scan2);
  formData.append("id2_type", data.id2_type);
  formData.append("id1", data.id1);
  formData.append("id2", data.id2);
  formData.append("id3", data.id3);
  formData.append("id3_type", data.id3_type);
  formData.append("id4", data.id4);
  formData.append("id4_type", data.id4_type);
  formData.append("post_code", data.post_code);
  formData.append("address_line_1", data.address_line_1);
  formData.append("address_line_2", data.address_line_2);
  formData.append("city", data.city);
  formData.append("state_province", data.state_province);

  const runUpgradeProfile = async () => {
    let token = await Cookies.get("access");
    Axios.post(`${BASE_URL}/remitter/profile/upgrade`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: UPGRADE_PROFILE,
          payload: res.data.result.message,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        console.log("Return: ", error);
        dispatch({
          type: UPGRADE_PROFILE_ERROR,
          payload: error,
        });
      });
  };
  runUpgradeProfile();
};

export const setUpgradeProfileLoading = () => {
  return {
    type: SET_UPGRADE_PROFILE_LOADING,
  };
};

export const clearUpgradeProfileError = () => {
  return {
    type: CLEAR_UPGRADE_PROFILE_ERROR,
  };
};

export const clearUpgradeProfile = () => {
  return {
    type: CLEAR_UPGRADE_PROFILE,
  };
};

export const initiatePayment = (data) => (dispatch) => {
  dispatch(setInitiatePaymentLoading());
  const runInitiatePayment = async () => {
    let token = await Cookies.get("access");
    Axios.post(`${BASE_URL}/payments/initiate`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: INITIATE_PAYMENT,
          payload: res.data.result,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: INITIATE_PAYMENT_ERROR,
          payload: error,
        });
      });
  };

  runInitiatePayment();
};

export const setInitiatePaymentLoading = () => {
  return {
    type: INITIATE_PAYMENT_LOADING,
  };
};

export const clearInitiatePaymentError = () => {
  return {
    type: CLEAR_INITIATE_PAYMENT_ERROR,
  };
};

export const clearInitiatePayment = () => {
  return {
    type: CLEAR_INITIATE_PAYMENT,
  };
};

export const searchRemitter = (data) => (dispatch) => {
  dispatch(setSearchRemitterLoading());

  const runSearchRemitter = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/existing/search`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: SEARCH_REMITTER,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SEARCH_REMITTER_ERROR,
          payload: error,
        });
      });
  };

  runSearchRemitter();
};

export const setSearchRemitterLoading = () => {
  return {
    type: SEARCH_REMITTER_LOADING,
  };
};

export const clearSearchRemitter = () => {
  return {
    type: CLEAR_SEARCH_REMITTER,
  };
};

export const clearSearchRemitterError = () => {
  return {
    type: CLEAR_SEARCH_REMITTER_ERROR,
  };
};

export const pollPayment = (data) => (dispatch) => {
  dispatch(setPollPaymentLoading());
  const runPollPayment = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/payments/poll`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: POLL_PAYMENT,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: POLL_PAYMENT_ERROR,
          payload: error,
        });
      });
  };

  runPollPayment();
};

export const setPollPaymentLoading = () => {
  return {
    type: SET_POLL_PAYMENT_LOADING,
  };
};

export const clearPollPaymentError = () => {
  return {
    type: CLEAR_POLL_PAYMENT_ERROR,
  };
};

export const clearPollPayment = () => {
  return {
    type: CLEAR_POLL_PAYMENT,
  };
};

export const sendOTP = (data) => (dispatch) => {
  dispatch(setSendOTPloading());

  const runSendOTP = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/existing/send-otp`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: SEND_OTP,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: SEND_OTP_ERROR,
          payload: error,
        });
      });
  };

  runSendOTP();
};

export const setSendOTPloading = () => {
  return {
    type: SEND_OTP_LOADING,
  };
};

export const clearSendOTPerror = () => {
  return {
    type: CLEAR_SEND_OTP_ERROR,
  };
};

export const clearSendOTP = () => {
  return {
    type: CLEAR_SEND_OTP,
  };
};

export const verifyOTP = (data) => (dispatch) => {
  dispatch(setVerifyOTPloading());

  const runVerifyOTP = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/existing/verify-otp`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: VERIFY_OTP,
          payload: res.data,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: VERIFY_OTP_ERROR,
          payload: error,
        });
      });
  };

  runVerifyOTP();
};

export const setVerifyOTPloading = () => {
  return {
    type: VERIFY_OTP_LOADING,
  };
};

export const clearVerifyOTPerror = () => {
  return {
    type: CLEAR_VERIFY_OTP_ERROR,
  };
};

export const clearVerifyOTP = () => {
  return {
    type: CLEAR_VERIFY_OTP,
  };
};

export const onboardRemitter = (data) => (dispatch) => {
  dispatch(setonboardRemitterLoading());

  const runOnboardRemitter = async () => {
    let token = await Cookies.get("access");

    Axios.post(`${BASE_URL}/remitter/existing/onboard`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        dispatch({
          type: ONBOARD_REMITTER,
          payload: res.data,
        });
        dispatch({
          type: PROFILE,
          payload: res.data.result,
        });
        dispatch({
          type: AUTHORIZATION,
          payload: res.data.result.middleware,
        });
      })
      .catch((err) => {
        let error = formatErrors(err);
        dispatch({
          type: ONBOARD_REMITTER_ERROR,
          payload: error,
        });
      });
  };

  runOnboardRemitter();
};

export const setonboardRemitterLoading = () => {
  return {
    type: ONBOARD_REMITTER_LOADING,
  };
};

export const clearOnboardRemitterError = () => {
  return {
    type: CLEAR_ONBOARD_REMITTER_ERROR,
  };
};

export const clearOnboardRemitter = () => {
  return {
    type: CLEAR_ONBOARD_REMITTER,
  };
};

const formatErrors = (err) => {
  let errors = [];
  //  console.log(err.response)
  let responseData = err.response ? err.response.data : null;

  if (responseData && responseData.result && responseData.result.errors) {
    errors = responseData.result.errors;
    errors.forEach(function (obj) {
      if (obj.message) {
        obj.error = obj.message;
        delete obj.message;
      }
    });
    // console.log(errors)
  } else if (responseData && responseData.result && responseData.result.message) {
    errors = [
      {
        field: null,
        error: responseData.result.message,
      },
    ];
  } else {
    errors = [
      {
        field: null,
        error: err.message,
      },
    ];
  }

  // if(process.env.REACT_APP_ENVIRONMENT !== "development"){
  // Bugsnag.notify(new Error(JSON.stringify(errors)));
  // }

  return errors;
};
